<template>
    <div class="pa-3">
        <div class="pa-3 white rounded">
            <v-text-field :value="orderNum" @input="setOrderNum" placeholder="Order number" flat hide-details solo-inverted 
                v-mask="'XXX-#########'" @keydown.enter="loadOrder" ref="orderNum" @focus="focusField = 'orderNum'" @blur="onBlur"></v-text-field>
        </div>

        <v-card flat class="mt-3" :loading="loadingView">
            <v-card-text v-show="!compact" :class="order != null && order.changes.length > 0 ? 'modified rounded' : 'rounded'">
                <v-row v-if="order != null">
                    <v-col cols="12" v-if="order.statusId == 24">
                        <v-alert color="error" text border="left" class="font-weight-bold">This order has been cancelled</v-alert>
                    </v-col>
                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Order Information</div>

                        <div class="text-left">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip :color="orderStatusColor(order.status)" label class="white--text mb-3 headline" 
                                        v-bind="attrs" v-on="on">
                                        {{ order.foreignId }}
                                    </v-chip>
                                </template>
                                <span>{{ order.status }}</span>
                            </v-tooltip>
                        </div>

                        <v-simple-table>
                            <tbody>
                                <tr>
                                    <th>Status</th>
                                    <td>{{ order.status }}</td>
                                </tr>
                                <tr>
                                    <th>Pickup location</th>
                                    <td>[{{ order.pickupLocation.wintuxID}}] {{ order.pickupLocation.name }}  |  {{ order.pickupLocation.phone }}</td>
                                </tr>
                                <tr>
                                    <th>Book date</th>
                                    <td>{{ formatDate( order.orderDate, 'YYYY-MM-DD @ hh:mm a' ) }}</td>
                                </tr>
                                <tr>
                                    <th>Pickup date</th>
                                    <td>{{ formatDate( order.wantDate, 'YYYY-MM-DD @ hh:mm a' ) }}</td>
                                </tr>
                                <tr>
                                    <th>Use date</th>
                                    <td>{{ order.useDate }}</td>
                                </tr>
                                <tr>
                                    <th>Return date</th>
                                    <td>{{ order.returnDate }}</td>
                                </tr>
                                <tr>
                                    <th>Customer type</th>
                                    <td>{{ order.eventRole }}</td>
                                </tr>
                                <tr>
                                    <th>Event</th>
                                    <td>{{ order.eventId == '0' ? '' : order.eventId }}</td>
                                </tr>
                                <tr>
                                    <th>Clerk</th>
                                    <td>{{ order.employee }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>

                        <div class="subtitle-1 my-3">Comments</div>
                        <div>{{ order.comments == '' ? 'No comments available' : order.comments }}</div>
                    </v-col>

                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Customer Information</div>
                        <customer-info :customer="order.customer" :edit="false" :avatar="false" show-measurements />
                    </v-col>

                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Items</div>

                        <!-- <v-text-field v-model="barcode" placeholder="Scan item" flat hide-details solo-inverted 
                            v-mask="'############'" @keydown.enter="assignItemToOrder" ref="barcode" @focus="focusField = 'barcode'" 
                            @blur="onBlur" class="mb-3"></v-text-field> -->

                        <barcode-field v-model="barcode" class="mb-3" ref="barcode" @focus="focusField = 'barcode'" @blur="onBlur"
                            @submit="assignItemToOrder" @openOrder="openOrder" v-if="order.statusId != 24" />

                        <package-viewer :value="orderPackage" :items-assigned="order.assignedItems" :changes="order.changes" itemsonly assignation />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text v-show="compact" :class="order != null && order.changes.length > 0 ? 'modified rounded' : 'rounded'">
                <div v-if="order != null">
                    <div class="d-flex align-center">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip :color="orderStatusColor(order.status)" label class="white--text mb-3 headline" 
                                    v-bind="attrs" v-on="on">
                                    {{ order.foreignId }}
                                </v-chip>
                            </template>
                            <span>{{ order.status }}</span>
                        </v-tooltip>

                        <v-spacer></v-spacer>

                        <div class="mb-2" v-if="order.changes.length > 0">
                            <v-chip small label color="deep-orange" class="white--text">MODIFIED</v-chip>
                        </div>
                    </div>

                    <v-alert color="error" text border="left" class="font-weight-bold" v-if="order.statusId == 24">This order has been cancelled</v-alert>

                    <barcode-field v-model="barcode" class="mb-3" ref="barcode2" @focus="focusField = 'barcode2'" @blur="onBlur"
                        @submit="assignItemToOrder" @openOrder="openOrder" v-else />

                    <package-viewer :value="orderPackage" :items-assigned="order.assignedItems" :changes="order.changes" itemsonly assignation />

                    <div class="subtitle-1 my-3">Comments</div>
                    <div>{{ order.comments == '' ? 'No comments available' : order.comments }}</div>
                </div>
            </v-card-text>

            <v-card-actions v-if="order != null">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn depressed @click="compact = !compact" icon v-on="on">
                            <v-icon>{{ compact ? 'mdi-view-compact-outline' : 'mdi-view-headline' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ compact ? 'Show full view' : 'Show compact view' }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-btn depressed color="grey darken-4" class="white--text" @click="next" v-show="order.statusId != 24">Continue</v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="continueDialog" width="500" persistent>
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    Are you sure you want to continue? There are items that has not been assigned yet.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="continueDialog = false; onBlur();">No</v-btn>
                    <v-btn depressed color="primary" @click="clear">Yes, continue</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="newOrderWarning" width="500">
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    There are items in this order that hasn't been assigned yet. <br /><b>Are you sure you want to open another order?</b>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="newOrderWarning = false; onBlur();">No</v-btn>
                    <v-btn depressed color="primary" @click="openOrderForce">Yes, open new order</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <force-dialog v-model="forceDialog" :text="forceDialogText" :forcing="forcing" @force="forceItemToOrder" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BarcodeField from '@/components/BarcodeField.vue';
import PackageViewer from '@/components/PackageViewer.vue';
import CustomerInfo from '@/components/CustomerInfo.vue';
import ForceDialog from '@/components/ForceDialog.vue';
import { mask } from 'vue-the-mask';
import moment from 'moment';

export default {
    data: () => ({
        loadingView         : false,
        barcode             : '',
        focusField          : 'orderNum',

        assignedItems       : [],

        continueDialog      : false,
        forceDialog         : false,
        forceDialogText     : '',
        forceReason         : '',
        forceReasons        : [],
        forcing             : false,
        newOrderWarning     : false,
        newOrderId          : '',

        compact             : true // show compact version when true
    }),
    computed: {
        ...mapGetters({
            orderNum        : 'stations/qc/orderNum',
            order           : 'stations/qc/order',
        }),
        orderPackage() {
            if( this.order == null )
                return {};

            let m = this.order.measurements;
            let items = [];
            this.order.items.forEach(i => {
                let size = '';
                if( i.categoryId == 'C' )       size = `${m.coatSize}${m.coatLength} - ${m.coatSleeve}`;
                else if( i.categoryId == 'P' )  size = `${m.pantWaist}${m.pantType} - ${m.pantOutseam}`;
                else if( i.categoryId == 'S' )  size = `${m.shirtSize}`;
                else if( i.categoryId == 'N' )  size = `${m.tieSize}`;
                else if( i.categoryId == 'V' )  size = `${m.vestSize}`;
                else if( i.categoryId == 'SH' ) size = `${m.shoeSize}${m.shoeType}`;
                else if( i.categoryId == 'CB' ) size = `${m.cummerbundSize}`;
                else                            size = '';

                items.push({
                    ...i,
                    size
                });
            });

            return {
                items: items,
            };
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.onBlur();
        });
    },
    methods: {
        ...mapActions({
            getOrder    : 'stations/qc/loadOrder',
            clearOrder  : 'stations/qc/clearOrder',
            setOrderNum : 'stations/qc/setOrderNum',
            assignItem  : 'stations/qc/assignItem',
            forceItem   : 'stations/qc/forceItem',
        }),
        async loadOrder() {
            try {
                if( this.orderNum == '' )
                    return;

                this.playBeep2();

                this.loadingView = true;
                this.clearOrder();
                await this.getOrder();
                this.loadingView = false;

                if( this.compact )
                    this.$refs.barcode2.focus();
                else
                    this.$refs.barcode.focus();
            }
            catch(error) {
                this.loadingView = false;
            }
            finally {
                this.setOrderNum('');
            }
        },
        async assignItemToOrder() {
            try {
                if( this.barcode == '' )
                    return;

                let res = await this.assignItem( this.barcode );

                if( res && res.canBeForced === true ) {
                    this.playWarning();
                    this.forceDialogText = res.text;
                    this.forceReason = res.forceReason;
                    this.forceDialog = true;
                }
                else {
                    this.forceReasons = [];
                }

                this.barcode = '';
                this.$refs.barcode.focus();
                this.maybePlaySound();
            }
            catch(error) {
                this.barcode = '';
                this.$refs.barcode.focus();
                this.playError();
            }
        },
        async forceItemToOrder() {
            try {
                this.forcing = true;
                this.forceReasons.push( this.forceReason );
                this.forceReason = '';
                let res = await this.forceItem( this.forceReasons );
                this.forcing = false;

                this.forceDialog = false;

                if( res && res.canBeForced === true ) {
                    this.playWarning();
                    this.forceDialogText = res.text;
                    this.forceReason = res.forceReason;
                    this.forceDialog = true;
                }
                else {
                    this.forceReasons = [];
                    this.maybePlaySound();
                }

                this.barcode = '';
                this.$refs.barcode.focus();
            }
            catch(error) {
                this.forcing = false;
                this.barcode = '';
                this.$refs.barcode.focus();
            }
        },
        openOrder(b) {
            let needed = this.order.items.reduce((prev, curr) => prev + (curr.hasBarcode && curr.categoryId != 'C' && curr.categoryId != 'SH' ? 1 : 0), 0);
            let assigned = this.order.assignedItems.reduce((prev, curr) => prev + (curr.categoryId != 'C' && curr.categoryId != 'SH' ? 1 : 0), 0);

            if( needed != assigned ) {
                this.playWarning();
                this.newOrderWarning = true;
                this.newOrderId = b;
                return
            }

            this.setOrderNum(b); 
            this.loadOrder(); 
        },
        openOrderForce() {
            this.setOrderNum(this.newOrderId); 
            this.loadOrder(); 
            this.newOrderWarning = false;
            this.newOrderId = '';
            this.forceReason = '';
            this.forceReasons = [];
        },
        maybePlaySound() {
            let needed = this.order.items.reduce((prev, curr) => prev + (curr.hasBarcode && curr.categoryId != 'C' && curr.categoryId != 'SH' ? 1 : 0), 0);
            let assigned = this.order.assignedItems.reduce((prev, curr) => prev + (curr.categoryId != 'C' && curr.categoryId != 'SH' ? 1 : 0), 0);

            if( needed == assigned ) {
                this.playSuccess();
            }
        },
        next() {
            let needed = this.order.items.filter(i => i.hasBarcode);
            if( needed.length != this.order.assignedItems.length ) {
                this.continueDialog = true;
                return;
            }

            this.clear();
        },
        clear() {
            this.clearOrder();
            this.continueDialog = false;
            this.$refs.orderNum.focus();
        },
        formatDate( date, f = 'YYYY-MM-DD' ) {
            return moment(date).format(f);
        },
        onBlur() {
            setTimeout(() => {
                if( this.order == null || this.order.statusId == 24 || this.focusField == 'orderNum' ) 
                    this.$refs.orderNum.focus();
                else if( this.compact )
                    this.$refs.barcode2.focus();
                else 
                    this.$refs.barcode.focus();
            }, 100);
        },
    },
    components: { BarcodeField, PackageViewer, CustomerInfo, ForceDialog },
    directives: { mask }
}
</script>

<style lang="scss" scoped>
.modified {
    border: 3px solid #FF5722;
}
</style>